<template>
  <div class="games-detail">
    <div class="games-detail-top">
      <div class="games-detail-top-left">
        <img class="games-detail-main-pic" :src="tempCurrentProduct.productCoverUrl" alt="商品主图" >
      </div>
      <div class="games-detail-top-right">
        <div class="games-detail-title">
          <p class="games-detail-title-text">{{ tempCurrentProduct.productIntroduction }}</p>
        </div>
        <div class="games-detail-props">
          <div class="games-detail-props-left">
            <p class="games-detail-props-left-top">商品编号：<span class="games-detail-props-value-text">{{ 'T'+tempCurrentProduct.gameId+tempCurrentProduct.productId }}</span></p>
            <p class="games-detail-props-left-bottom">游戏区服：<span class="games-detail-props-value-text">{{ tempCurrentProduct.gameZone }}</span></p>
          </div>
          <div class="games-detail-props-right">
            <p class="games-detail-props-right-top">商品类型：<span class="games-detail-props-value-text">账号</span></p>
            <p class="games-detail-props-right-bottom">商品上架时间：<span class="games-detail-props-value-text">{{ tempCurrentProduct.createTime }}</span></p>
          </div>
        </div>
        <div class="games-detail-price-and-collect-and-btn">
          <div class="games-detail-price">
            <p class="games-detail-price-text">￥{{tempCurrentProduct.productPrice.toFixed(2)}}元</p>
          </div>
          <div class="games-detail-collect-and-btn">
            <div class="games-detail-collect" @click="clickProductCollectHnadler(tempCurrentProduct)">
              <img class="games-detail-collect-pic" :src="tempCurrentProduct.asCollect?'/images/home/collect_icon.png':'/images/home/uncollect_icon.png'" alt="收藏图标" >
              <p class="games-detail-collect-text" :style="{color:tempCurrentProduct.asCollect?'#FFA677':''}">收藏</p>
            </div>
            <div class="games-detail-chat-btn">
              <p class="games-detail-chat-btn-text">咨询客服</p>
            </div>
            <div class="games-detail-buy-btn" @click="clickBuyBtnHandler(tempCurrentProduct.productId)">
              <p class="games-detail-buy-btn-text">立即购买</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="games-detail-bottom">
      <!-- 商品菜单 -->
      <div class="games-detail-menu">
        <ul>
          <li @click="clickGamesDetailMenuHandler(0)">
            <p class="games-detail-menu-title" :style="currentMenuIndex===0?titleStyle:''">商品详情</p>
          </li>
          <li @click="clickGamesDetailMenuHandler(1)">
            <p class="games-detail-menu-title" :style="currentMenuIndex===1?titleStyle:''">交易须知</p>
          </li>
          <li @click="clickGamesDetailMenuHandler(2)">
            <p class="games-detail-menu-title" :style="currentMenuIndex===2?titleStyle:''">免责声明</p>
          </li>
          <div class="menu-slider" :style="{left:menuSliderLeft}"></div>
        </ul>
      </div>
      <!-- 商品详情 -->
      <div class="games-detail-content" v-if="currentMenuIndex===0">
        <div class="games-detail-props-content">
          <!-- 基本信息1 -->
          <div class="games-detail-props-base-info-box">
            <div class="games-detail-base-info">
              <p class="games-detail-base-info-title">基础信息</p>
            </div>
            <div class="games-detail-props-info">
              <ul id="baseInfoUl">
                <li>
                  <p class="games-detail-props-item">游戏名称：{{ tempCurrentProduct.gameName }}</p>
                </li>
                <li>
                  <p class="games-detail-props-item">交易类型：账号/成品号</p>
                </li>
                <li>
                  <p class="games-detail-props-item">有无二次实名: {{ tempCurrentProduct.productTag.split(",")[0] }}</p>
                </li>
                <li>
                  <p class="games-detail-props-item">有无包赔: {{ tempCurrentProduct.productTag.split(",")[1] }}</p>
                </li>
                <li>
                  <p class="games-detail-props-item">有无防沉迷: {{ tempCurrentProduct.productTag.split(",")[2] }}</p>
                </li>
                <!-- <li>
                  <p class="games-detail-props-item">二次实名：可改实名</p>
                </li> -->
              </ul>
            </div>
          </div>
          <!-- 基本信息2 -->
          <div class="games-detail-other-props-info" v-for="(value,key) in tempCurrentProduct.productDetailsObject" :key="key">
            <div class="games-detail-props-title">
              <p class="games-detail-props-title-text">{{key}}</p>
            </div>
            <div class="games-detail-props-value">
              <p class="games-detail-props-value-text">{{value}}</p>
            </div>
          </div>
        </div>
        <!-- 基本信息3 -->
        <div class="games-detail-screenshot-info">
          <ul>
            <li v-for="(item,index) in tempCurrentProduct.productScreenshot.split(',')" :key="index">
              <img class="games-detail-screenshot-pic" :src="item" alt="商品详情截图" >
            </li>
          </ul>
        </div>
      </div>
      <!-- 商品详情 -->
      <games-trading-notice v-if="currentMenuIndex===1"></games-trading-notice>
      <games-disclaimers v-if="currentMenuIndex===2"></games-disclaimers>
    </div>
  </div>
</template>

<script>
import GamesTradingNotice from './games-trading-notice.vue';
import GamesDisclaimers from './games-desclaimers.vue';
import {mapState,mapMutations} from 'vuex';
import {webAddOrCancelProductCollect,commitOrderAndPayForAliPay} from '@/services/buyList';
export default {
  name:'GamesDetail',
  components:{
    GamesTradingNotice,
    GamesDisclaimers
  },
  props:{
    currentProduct:{
      type:Object,
      default:null
    }
  },
  data(){
    return{
      currentMenuIndex:0,
      tempCurrentProduct:null
    }
  },
  methods:{
    ...mapMutations('buyList',['setCurrentShowGameDetailItem']),
    //点击菜单操作
    clickGamesDetailMenuHandler(index){
      //console.log('当前索引：'+index)
      this.currentMenuIndex = index;
    },
    //当一行不够3个li的时候，补充li元素
    initLoadLiElementTag(){
      const list = document.getElementById('baseInfoUl');
      const items = list.getElementsByTagName('li');
      const count = items.length;
      const remainder = count % 3;
      // console.log('initLoadLiElementTag---'+list.offsetWidth) //1039
      // console.log('initLoadLiElementTag---'+list.clientWidth) //1039
      // 如果除以3有余数，则需要补充空的li元素
      if (remainder !== 0) {
        const itemsToAdd = 3 - remainder; // 计算需要补充的空元素数
        for (let i = 0; i < itemsToAdd; i++) {
          const emptyLi = document.createElement('li');
          emptyLi.textContent = ''; // 可以选择插入空内容
          // emptyLi.style.borderRight = '1px solid #ECECEC';
          emptyLi.style.width='calc(100%/3)';
          list.appendChild(emptyLi);
        }
      }
      // 为每个 li 添加右边框，排除每行的最后一个 li
      for (let i = 0; i < items.length; i++) {
        if ((i + 1) % 3 !== 0) { // 判断是否为每行的最后一项
          items[i].style.borderRight = '1px solid #ECECEC'; // 添加右边框
        }
      }
    },
    //点击商品收藏操作
    async clickProductCollectHnadler(item){
      let userToken = JSON.parse(localStorage.getItem('userToken'));
        if(!userToken){
            this.$message.warning('请先登录')
            return;
        }
        try{
            let tempData = {
                productId:item.productId,
                collectStatus:!item.asCollect
            }
            const {data}  = await webAddOrCancelProductCollect(tempData);
            if(data.code === 200){
            item.asCollect = !item.asCollect;
            this.setProductionCollectionStatus(item);
            }
        }catch(err){}
    },
    //点击商品购买操作
    async clickBuyBtnHandler(productId){
      try{
        const {data} = await commitOrderAndPayForAliPay(productId);
        if(data.code==200){
          //document.write(data.data);
          this.createAndSubmitForm(data.data);
        }else{
          this.$message.warning('下单失败')
        }
      }catch(err){}
    },
    //打开新的窗口,自动运行传入的表单脚本
    createAndSubmitForm(formString) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(formString, 'text/html');
        const form = doc.querySelector('form');
        if (!form) {
          console.error('Could not find form element in provided string.');
          return;
        }
        form.target = '_blank'; // 设置 target 属性在新窗口打开
        // 将 form 添加到文档中 (可以创建一个隐藏的 div 容器)
        const container = document.createElement('div');
        container.style.display = 'none';
        container.appendChild(form);
        document.body.appendChild(container);
        // 提交表单
        form.submit();
        // 可选：移除容器
        setTimeout(() => {
          document.body.removeChild(container);
        }, 1000);
    },
  },
  computed:{
    ...mapState({ 
      stateCurrentProduct:state=>state.buyList.currentShowGameDetailItem
    }),
    titleStyle(){
      return 'color:#F02233;font-weight: bold;'
    },
    menuSliderLeft(){ // 0  60+50  120+100
      return this.currentMenuIndex*(50+60)+'px';
    }
  },
  created(){
    this.$nextTick(()=>{
      this.initLoadLiElementTag();
    })
    window.scrollTo(0,0);
    if(this.currentProduct){
      this.tempCurrentProduct = this.currentProduct;
      this.setCurrentShowGameDetailItem(this.currentProduct);
    }else{
      this.tempCurrentProduct = this.stateCurrentProduct;
    }
  }
}
</script>

<style lang="less" scoped>
@item-width: calc(100%/3);
.games-detail{
    width: 1200px;
    .games-detail-top{
      margin-top: 20px;
      width: 1200px;
      height: 320px;
      background: #FFFFFF;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .games-detail-top-left{
        margin-left: 20px;
        width: 500px;
        height: 280px;
        border-radius: 4px;
        .games-detail-main-pic{
          width: 500px;
          height: 280px;
        }
      }
      .games-detail-top-right{
        margin-right: 20px;
        width: 640px;
        height: 280px;
        display: flex;
        flex-direction: column;
        .games-detail-title{
          width: 640px;
          height: 52px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: default;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          line-clamp: 2;
          -webkit-line-clamp: 2;
          word-break: break-all;
          .games-detail-title-text{
            width: 640px;
            height: 52px;
            font-family: MicrosoftYaHei;
            font-size: 18px;
            color: #131415;
            line-height: 26px;
            text-align: left;
            font-style: normal;
          }
        }
        .games-detail-props{
          margin-top: 19px;
          width: 640px;
          height: 96px;
          display: flex;
          cursor: default;
          background-image: url(../../../../public/images/home/product_detail_props_bg.png);
          background-size: 640px 96px;
          // border: 1px dashed #888888;
          .games-detail-props-left{
            width: 320px;
            height: 96px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-left: 21px;
            .games-detail-props-left-top{
              height: 17px;
              font-family: MicrosoftYaHei;
              font-size: 13px;
              color: #888888;
              line-height: 17px;
              text-align: left;
              font-style: normal;
            }
            .games-detail-props-left-bottom{
              margin-top: 20px;
              height: 17px;
              font-family: MicrosoftYaHei;
              font-size: 13px;
              color: #888888;
              line-height: 17px;
              text-align: left;
              font-style: normal;
            }
            .games-detail-props-value-text{
              height: 17px;
              font-family: MicrosoftYaHei;
              font-size: 13px;
              color: #222222;
              line-height: 17px;
              text-align: left;
              font-style: normal;
            }
          }
          .games-detail-props-right{
            width: 320px;
            height: 96px;
            display: flex;
            justify-content: center;
            padding-left: 21px;
            flex-direction: column;
            .games-detail-props-right-top{
              height: 17px;
              font-family: MicrosoftYaHei;
              font-size: 13px;
              color: #888888;
              line-height: 17px;
              text-align: left;
              font-style: normal;
            }
            .games-detail-props-right-bottom{
              margin-top: 20px;
              height: 17px;
              font-family: MicrosoftYaHei;
              font-size: 13px;
              color: #888888;
              line-height: 17px;
              text-align: left;
              font-style: normal;
            }
            .games-detail-props-value-text{
              height: 17px;
              font-family: MicrosoftYaHei;
              font-size: 13px;
              color: #222222;
              line-height: 17px;
              text-align: left;
              font-style: normal;
            }
          }
        }
        .games-detail-price-and-collect-and-btn{
          margin-top: 73px;
          width: 640px;
          height: 40px;
          display: flex;
          justify-content: space-between;
          .games-detail-price{
            height: 40px;
            display: flex;
            align-items: center;
            cursor: default;
            .games-detail-price-text{
              height: 30px;
              font-family: MicrosoftYaHei, MicrosoftYaHei;
              font-weight: bold;
              font-size: 22px;
              color: #F02233;
              line-height: 30px;
              text-align: left;
              font-style: normal;
            }
          }
          .games-detail-collect-and-btn{
            display: flex;
            user-select: none;
            .games-detail-collect{
              cursor: default;
              display: flex;
              height: 40px;
              align-items: center;
              .games-detail-collect-pic{
                width: 40px;
                height: 40px;
                user-select: none;
              }
              .games-detail-collect-text{
                height: 21px;
                font-family: MicrosoftYaHei;
                font-size: 16px;
                color: #999999;
                line-height: 21px;
                text-align: left;
                font-style: normal;
                user-select: none;
              }
            }
            .games-detail-chat-btn{
              margin-left: 30px;
              width: 140px;
              height: 40px;
              border-radius: 6px;
              border: 1px solid #FFE8EA;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: default;
              .games-detail-chat-btn-text{
                height: 21px;
                font-family: MicrosoftYaHei;
                font-size: 16px;
                color: #F02233;
                line-height: 21px;
                text-align: right;
                font-style: normal;
                user-select: none;
              }
            }
            .games-detail-buy-btn{
              margin-left: 20px;
              width: 140px;
              height: 40px;
              background: #F02233;
              border-radius: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: default;
              .games-detail-buy-btn-text{
                height: 21px;
                font-family: MicrosoftYaHei;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 21px;
                text-align: right;
                font-style: normal;
                user-select: none;
              }
            }
          }
          
        }
      }
    }

    .games-detail-bottom{
      margin-top: 20px;
      width: 1200px;
      background: #FFFFFF;
      padding-bottom: 30px;
      border-radius: 6px;
      .games-detail-menu{
        width: 1200px;
        height: 52px;
        background: #FFFFFF;
        box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.03);
        border-radius: 6px 6px 0px 0px;
        margin-bottom: 1px;
        ul{
          margin-left: 30px;
          width: 1140px;
          height: 52px;
          list-style-type: none;
          position: relative;
          display: flex;
          align-items: center;
          li{
            margin-left: 50px;
            list-style: none;
            cursor: default;
            .games-detail-menu-title{
              height: 20px;
              font-family: MicrosoftYaHei;
              font-size: 15px;
              color: #555555;
              line-height: 20px;
              text-align: left;
              font-style: normal;
            }
            &:first-child{
              margin-left: 0px;
            }
            &:hover .games-detail-menu-title{
              color: #F02233;
              font-weight: bold;
            }
          }
          .menu-slider{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 60px;
            height: 2px;
            background: #F02233;
            transition: all 0.3s ease-in-out;
          }
        }
      }

      .games-detail-content{
        width: 1200px;
        padding-top: 20px;
        background: #FFFFFF;
        .games-detail-props-content{
          margin-left: 20px;
          width: 1160px;
          // height: 267px;
          border-radius: 6px;
          border: 1px solid #ECECEC;
          .games-detail-props-base-info-box{
            width: 1160px;
            display: flex;
            border-bottom: 1px solid #ECECEC;
            .games-detail-base-info{
              width: 120px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-right: #ECECEC;
              cursor: default;
              .games-detail-base-info-title{
                height: 20px;
                font-family: MicrosoftYaHei;
                font-size: 15px;
                color: #666666;
                line-height: 20px;
                text-align: left;
                font-style: normal;
              }
            }
            .games-detail-props-info{
              width: 1039px;
              border-left:1px solid #ECECEC;
              box-sizing: border-box;
              ul{
                width: 1039px;
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;
                box-sizing: border-box;
                padding: 0;
                cursor: default;
                li{
                  // width: 346px;
                  width: @item-width;
                  box-sizing: border-box;
                  height: 36px;
                  list-style: none;
                  border-right:1px solid #ECECEC;
                  display: flex;
                  align-items: center;
                  // background-color: pink;
                  .games-detail-props-item{
                    margin-left: 20px;
                    height: 20px;
                    font-family: MicrosoftYaHei;
                    font-size: 15px;
                    color: #222222;
                    line-height: 20px;
                    text-align: left;
                    font-style: normal;
                  }
                  &:nth-child(3n){
                    border-right: 0px solid #ECECEC;
                  }
                }
              }
            }
          }

          .games-detail-other-props-info{
            display: flex;
            border-bottom: 1px solid #ECECEC;
            cursor: default;
            .games-detail-props-title{
              width: 120px;
              display: flex;
              justify-content: center;
              align-items: center;
              .games-detail-props-title-text{
                height: 20px;
                font-family: MicrosoftYaHei;
                font-size: 15px;
                color: #666666;
                line-height: 20px;
                text-align: left;
                font-style: normal;
              }
            }
            .games-detail-props-value{
              width: 1039px;
              display: flex;
              align-items: center;
              border-left: 1px solid #ECECEC;
              padding: 9px 20px 10px 20px;
              .games-detail-props-value-text{
                font-family: MicrosoftYaHei;
                font-size: 15px;
                color: #222222;
                line-height: 30px;
                text-align: left;
                font-style: normal;
              }
            }
            &:last-child{
              border-bottom: none;
            }
          }
        }
        .games-detail-screenshot-info{
            width: 1160px;
            ul{
              list-style: none;
              padding: 0;
              li{  
                width: 1160px;
                // height: 643px;
                margin: 20px 0 0 20px;
                .games-detail-screenshot-pic{
                  width: 1160px;
                  // height: 643px;
                }
                &:first-child{
                  margin-top: 30px;
                }
              }
            }
          }
      }
    }
}
</style>